.MuiTableCell-root {
    font-size: 12px !important;
}

p{
    margin-bottom: 0;
    padding-bottom: 0;
}

.MuiTooltip-tooltip{
    background-color: #ffffff !important;
    color: #000 !important;
    border: 1px solid #7B8794;
    padding: 8px 19px !important;
    border-radius: 8px !important;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }



  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    max-width: 70%;
    padding: 31px 0 21px !important;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
  }
  
  .mySwiper .swiper-slide-thumb-active img {
    border: 2px solid #00DC84;
  }
  
  .swiper-slide img {
    display: block;
    width: 50%;
    height: 100%;
    object-fit: cover;
  }


  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 288px !important;
    border: 1px solid gray !important;
    padding: 20px !important;
    border-radius: 10px;
}

.swiper-button-next, .swiper-rtl .swiper-button-next {
    right: 288px !important;
    border: 1px solid gray !important;
    padding: 20px !important;
    border-radius: 10px;
}



  
  .swiper-button-next {
    background-image: url("../../../images/Vector.png") !important;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center !important;
    background-position: center !important;
  }

  .swiper-button-next {
    background-image: url("../../../images/Vector.png") !important;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center !important;
    background-position: center !important;
  }



  .swiper-button-prev {
    background-image: url("../../../images/Vector.png") !important;
    background-repeat: no-repeat;
    display: flex;
    transform: rotate(180deg);
    justify-content: center !important;
    background-position: center !important;
  }
  
  

  .swiper-button-next:after, .swiper-button-prev:after {
    text-transform: none!important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    opacity: 0;

}



